import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { Button } from '../../redesign';
import { Tooltip } from '@parsleyhealth/cilantro-ui';
import { CloseIcon } from '../../redesign/atoms/icons/close-icon/close-icon';
import { CheckIcon } from '../../redesign/atoms/icons/check-icon/check-icon';
import { InfoIcon } from '../../redesign/atoms/icons/info-icon/info-icon';
import { Special } from '@ph-bit/design.ui.icons.special';
import {
  BillingType,
  getStripeLink,
  PaymentType,
  PlanType
} from '../utils/get-stripe-link';

const checkmarkIcon = (
  <div
    key="checkmark"
    className="inline-block text-center text-white p-2 bg-ph-clover rounded-full"
  >
    <CheckIcon iconWidth="20" color="white" strokeWidth={2} />
  </div>
);

const closeIcon = (
  <div
    key="close"
    className="inline-block text-center text-white p-2 bg-ph-dark-gray rounded-full"
  >
    <CloseIcon iconWidth="20" color="white" strokeWidth={2} />
  </div>
);

const priceInfoTooltip = (price: string, monthlyPrice: number) => (
  <div className="flex flex-row gap-1 items-center justify-evenly">
    <span className="pr-0.5">${price}</span>
    {/*    <div className="flex">
      <Tooltip
        content={`$${monthlyPrice}/mo, billed annually, dependent on financing with Affirm.`}
      >
        <InfoIcon iconWidth="20" color="gray" strokeWidth={2} />
      </Tooltip>
    </div>*/}
  </div>
);
const pricingTable = {
  [PaymentType.IN_NETWORK]: {
    plans: [
      {
        name: PlanType.FOUNDATION,
        price: priceInfoTooltip('1,299', 108.25),
        priceDetails: (
          <div className="flex justify-center">
            <Tooltip content="Dependent on financing with Affirm.">
              <InfoIcon iconWidth="20" color="gray" strokeWidth={2} />
            </Tooltip>
          </div>
        )
      },
      {
        name: PlanType.TOTAL_HEALTH,
        price: priceInfoTooltip('2,999', 250),
        priceDetails: '($250/mo, billed annually)'
      }
    ],
    features: [
      {
        category: 'Functional Medicine Diagnostic',
        items: [
          {
            name: 'Annual Functional Lab Panel',
            values: ['1 included', '1 included']
          },
          {
            name: (
              <div className="flex items-end md:items-center">
                <span className="md:pr-1">Advanced Specialty Testing</span>
                <Tooltip content="Eligible specialty tests include GI, SIBO, male/female hormones, stress & cortisol response, and food sensitivity.">
                  <Special className="text-ph-sage" />
                </Tooltip>
              </div>
            ),
            values: [closeIcon, '1 included']
          }
        ]
      },
      {
        category:
          'Personalized Treatment Plan + Access to Parsley Clinical Support',
        items: [
          {
            name: 'Personalized Treatment Plan',
            values: [checkmarkIcon, checkmarkIcon]
          },
          {
            name:
              'Telemedicine Visit with Clinician Trained in Root-Cause Medicine (12 \n month access)',
            values: [
              "All visits billed through \n member's insurance",
              "All visits billed through \n member's insurance"
            ]
          },
          {
            name: 'Ongoing Messaging Support from Your Coach & Clinical Team',
            values: [checkmarkIcon, checkmarkIcon]
          }
        ]
      },
      {
        category: 'Personalized Meal Plan + Access to Parsley Health Coaching',
        items: [
          {
            name: 'Personalized Meal Plan',
            values: [checkmarkIcon, checkmarkIcon]
          },
          {
            name: 'Health Coaching Sessions (30 minutes)',
            values: ['1 included', '12 included']
          }
        ]
      },
      {
        category: 'Additional Perks & Benefits',
        items: [
          {
            name: 'Health and Wellness Partner Perks and Benefits',
            values: [checkmarkIcon, checkmarkIcon]
          },
          {
            name: '15% off Vitamin & Supplements',
            values: [checkmarkIcon, checkmarkIcon]
          }
        ]
      },
      {
        category: 'Optional Additional Fees',
        items: [
          {
            name: 'Telemedicine Visit with Root-Cause Trained Clinicians',
            values: [
              "Billed through member's insurance",
              "Billed through member's insurance"
            ]
          },
          {
            name: 'Health Coaching Sessions (30 minutes)',
            values: ['$120/visit', '$100/visit']
          },
          {
            name: 'Lab Tests',
            values: ['Cost dependent on test', 'Cost dependent on test']
          }
        ]
      }
    ]
  },
  [PaymentType.OUT_OF_NETWORK]: {
    plans: [
      {
        name: PlanType.FOUNDATION,
        price: priceInfoTooltip('1,599', 133.25),
        priceDetails: (
          <div className="flex justify-center">
            <span className="pr-0.5">($108.25/mo</span>
            <Tooltip content="Dependent on financing with Affirm.">
              <Special className="text-ph-sage" />
            </Tooltip>
            , billed annually)
          </div>
        )
      },
      {
        name: PlanType.TOTAL_HEALTH,
        price: priceInfoTooltip('3,299', 250),
        priceDetails: '($275/mo, billed annually)'
      }
    ],
    features: [
      {
        category: 'Functional Medicine Diagnostic',
        items: [
          {
            name: 'Annual Functional Lab Panel',
            values: ['1 included', '1 included']
          },
          {
            name: (
              <div className="flex items-end md:items-center">
                <span className="md:pr-1">Advanced Specialty Testing</span>
                <Tooltip content="Eligible specialty tests include GI, SIBO, male/female hormones, stress & cortisol response, and food sensitivity.">
                  <Special className="text-ph-sage mb-0.5" />
                </Tooltip>
              </div>
            ),
            values: [closeIcon, '1 included']
          }
        ]
      },
      {
        category:
          'Personalized Treatment Plan + Access to Parsley Clinical Support',
        items: [
          {
            name: 'Personalized Treatment Plan',
            values: [checkmarkIcon, checkmarkIcon]
          },
          {
            name:
              'Telemedicine Visit with Clinician Trained in Root-Cause Medicine (12 \n month access)',
            values: [
              'First 60-minute visit included. \n See pricing for additional \n visits below.',
              'First 60-minute visit included. \n See pricing for additional \n visits below.'
            ]
          },
          {
            name: 'Ongoing Messaging Support from Your Coach & Clinical Team',
            values: [checkmarkIcon, checkmarkIcon]
          }
        ]
      },
      {
        category: 'Personalized Meal Plan + Access to Parsley Health Coaching',
        items: [
          {
            name: 'Personalized Meal Plan',
            values: [checkmarkIcon, checkmarkIcon]
          },
          {
            name: 'Health Coaching Sessions (30 minutes)',
            values: ['1 included', '12 included']
          }
        ]
      },
      {
        category: 'Additional Perks & Benefits',
        items: [
          {
            name: 'Health and Wellness Partner Perks and Benefits',
            values: [checkmarkIcon, checkmarkIcon]
          },
          {
            name: '15% off Vitamin & Supplements',
            values: [checkmarkIcon, checkmarkIcon]
          }
        ]
      },
      {
        category: 'Optional Additional Fees',
        items: [
          {
            name: 'Telemedicine Visit with Root-Cause Trained Clinicians',
            values: [
              'Second 60-min Visit: $399 \n Additional 30-min Visits: $249',
              'Second 60-min Visit: $399 \n Additional 30-min Visits: $249'
            ]
          },
          {
            name: 'Health Coaching Sessions (30 minutes)',
            values: ['$120/visit', '$100/visit']
          },
          {
            name: 'Lab Tests',
            values: ['Cost dependent on test', 'Cost dependent on test']
          }
        ]
      }
    ]
  }
};

export const Pricing = () => {
  const [paymentType, setPaymentType] = useState<PaymentType>(
    PaymentType.IN_NETWORK
  );

  const togglePaymentType = () => {
    setPaymentType(
      paymentType === PaymentType.IN_NETWORK
        ? PaymentType.OUT_OF_NETWORK
        : PaymentType.IN_NETWORK
    );
  };

  return (
    <section className="flex flex-col gap-8 bg-ph-ivory py-16 md:py-24 md:gap-16 text-teodor">
      {/* Header */}
      <div className="mx-auto max-w-screen-md px-5 text-center text-ph-forest lg:max-w-[700px]">
        <div className="text-ph-h1 pb-4">
          Choose the program that’s right for you
        </div>
        <div className="text-ph-subtitle">
          Whether in-network (based on your insurance) or paying out-of-pocket,
          Parsley offers two best-in-class paths.
        </div>
        {/* Tabs */}
        <div className="flex justify-center pt-8 md:px-0 md:pt-12">
          <div className="bg-ph-gradient-mint-light flex gap-1 rounded-[10px] border border-solid border-ph-mint p-0.5">
            {[PaymentType.IN_NETWORK, PaymentType.OUT_OF_NETWORK].map(type => {
              return (
                <button
                  key={type}
                  onClick={togglePaymentType}
                  disabled={paymentType === type}
                  className={clsx(
                    'py-1.5',
                    'px-5',
                    'md:px-8',
                    'bg-transparent',
                    'disabled:bg-white',
                    'disabled:font-medium',
                    'disabled:shadow-[0_2px_10px_0_rgba(0,0,0,0.12)]',
                    'text-ph-p2',
                    'text-ph-forest',
                    'xl:text-base',
                    'rounded-[10px]'
                  )}
                >
                  {type}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      <div>
        {/* Table */}
        <div className="flex justify-center px-[30px] md:px-[117px]">
          <table>
            {/* Table Header */}
            <thead>
              <tr className="bg-ph-mint">
                <th className="md:min-w-auto text-ph-p2 relative min-w-[137px] border border-solid border-ph-clover md:text-xl md:leading-8 md:tracking-[-0.4px]">
                  <p className="absolute bottom-4 left-4">Features</p>
                </th>
                {pricingTable[paymentType].plans.map((plan, index) => (
                  <th
                    key={index}
                    className="md:min-w-auto min-w-[88px] border border-solid border-ph-clover py-4 md:pl-8 md:pr-8 lg:min-w-[338px] lg:pl-0 lg:pr-0"
                  >
                    <div className="flex flex-col items-center justify-center">
                      <div className="text-ph-p2 md:text-[21px] md:leading-[140%] md:tracking-[-0.21px]">
                        {plan.name}
                      </div>
                      <div className="flex items-center justify-center gap-1 pt-1">
                        <div className="text-ph-h6">{plan.price}</div>
                        {/*     
                       <div className="text-ph-caption hidden !text-ph-forest lg:block">
                          {plan.priceDetails}
                        </div>*/}
                      </div>
                      {paymentType === PaymentType.IN_NETWORK && (
                        <Button
                          trackingLabel="Schedule"
                          size="small"
                          className="mt-2"
                          variant="outlined"
                          destination="/contact/free-consultation"
                        >
                          Schedule A Call
                        </Button>
                      )}
                      {paymentType === PaymentType.OUT_OF_NETWORK && (
                        <div className="flex lg:flex-row flex-col lg:gap-2 gap-1 items-center justify-center">
                          <Button
                            openInNewTab
                            trackingLabel="Buy"
                            size="small"
                            className="mt-2"
                            variant="outlined"
                            destination={getStripeLink(
                              plan.name,
                              BillingType.MONTHLY
                            )}
                          >
                            Select Monthly
                          </Button>
                          <Button
                            openInNewTab
                            trackingLabel="Buy"
                            size="small"
                            className="mt-2"
                            variant="outlined"
                            destination={getStripeLink(
                              plan.name,
                              BillingType.YEARLY
                            )}
                          >
                            Select Yearly
                          </Button>
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {pricingTable[paymentType].features.map(
                (feature, featureIndex) => (
                  <Fragment key={featureIndex}>
                    {/* Feature Category Row */}
                    <tr className="bg-ph-seafom border border-solid border-ph-clover">
                      <td
                        colSpan={pricingTable[paymentType].plans.length + 1}
                        className="text-ph-action-large p-4 text-left align-middle"
                      >
                        {feature.category}
                      </td>
                    </tr>

                    {/* Feature Items */}
                    {feature.items.map((item, itemIndex) => (
                      <tr
                        key={itemIndex}
                        className="border border-solid border-ph-clover"
                      >
                        <td className="text-ph-caption align-middle border border-solid border-ph-clover p-4 pr-2 !text-start !text-ph-forest md:whitespace-pre-line md:pr-8 md:text-[16px] md:leading-[24px] md:tracking-[-0.32px]">
                          {item.name}
                        </td>
                        {item.values.map((value, valueIndex) => (
                          <td
                            key={valueIndex}
                            className="text-ph-caption align-middle border border-solid border-ph-clover px-1.5 py-4 text-center !text-ph-forest md:whitespace-pre-line md:px-4 md:text-[16px] md:leading-[24px] md:tracking-[-0.32px]"
                          >
                            {value}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                )
              )}
              {/* Disclaimer */}
              {paymentType === PaymentType.IN_NETWORK && (
                <tr>
                  <td colSpan={3}>
                    <div className="text-ph-caption px-[30px] pt-4 text-center !text-ph-light-grey md:px-0 md:pt-6 max-w-[1100px]">
                      * Member is responsible for understanding insurance
                      benefits and is responsible for patient share of
                      cost not paid by insurance which may include
                      co-payments, co-insurance, and deductibles.
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="lg:flex lg:justify-center gap-4 text-center">
        <Button
          trackingLabel="Schedule"
          size="large"
          variant="filled"
          className="block text-base tracking-[-0.16px] mx-auto mb-4 lg:mb-0 lg:mx-0"
          destination="/contact/free-consultation"
        >
          Schedule A Call
        </Button>
      </div>
    </section>
  );
};
