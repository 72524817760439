import React from 'react';
import { Button, Layout, Module, Structured } from '../redesign';
import { Hero } from '../parsley-web/marketing/Hero';
import MakingItAccessible from '../parsley-web/marketing/MakingItAccessible';
import { StructuredData } from '../data/new/pricing';
import { Pricing } from '../parsley-web/pricing-and-insurance/Pricing';

const PricingAndInsurance = () => {
  return (
    <Layout>
      <Hero
        heading="Transparent pricing, like healthcare should be"
        subheading="Meet two Parsley programs that combine functional diagnostics with effective root-cause care."
        image={{
          src:
            'https://cdn.sanity.io/images/mur2ep80/production/712be6450bab5a2bf7b00485517973b620e5c163-1444x1288.webp?w=2000&fit=max&auto=format',
          alt: 'Pricing and insurance'
        }}
        mobileImage={{
          src:
            'https://cdn.sanity.io/images/mur2ep80/production/3e8d6a7083d9b6aaab26c2d3101ec2e2dc57f9c0-750x540.png?w=770&fit=max&auto=format',
          alt: 'Pricing and insurance'
        }}
        primaryAction={{
          value: 'Schedule A Call',
          size: 'large',
          variant: 'filled',
          destination: '/contact/free-consultation'
        }}
      />

      <Pricing />

      <MakingItAccessible
        title="Now accepting insurance in New York and California"
        subheading={''}
        imageOnLeft
      />

      <Module animate>
        <h2 className="text-teodor w-full text-ph-h1 px-6 text-center">
          Insurance can be confusing. We’ve got you covered.
        </h2>
        <Structured {...StructuredData} />
      </Module>

      <section className="text-teodor">
        <div className="flex flex-col items-center rounded-b-[64px] bg-ph-gradient-peachy py-16 md:rounded-b-[96px] lg:py-24">
          <h2 className="text-ph-h1 px-6 text-center">Any more questions?</h2>
          <p className="text-ph-subtitle max-w-[698px] pb-8 pt-6 text-center md:pb-12 md:pt-8">
            Book a call with our Parsley advisors to confirm your in-network
            status and help find the best program for your needs today.
          </p>
          <Button
            variant="filled"
            size="large"
            destination="/contact/free-consultation"
            trackingLabel="Get in touch"
          >
            Get In Touch
          </Button>
        </div>
      </section>
    </Layout>
  );
};

export default PricingAndInsurance;
